import { actionTypesx } from '../actions/store.action'

const initialState = {
    id: 0,
    name: '',
    count: 0
}

const storeReducer = (state = initialState, { type, payload }) => {

    switch (type) {

    case actionTypesx.CHANGEX:
        //console.log (payload.data);
        //localStorage.setItem('store_id', payload.data.id)
        //localStorage.setItem('logo', payload.data.logo)
        return { ...state, ...payload.data }

    default:
        return state
    }
}

export default storeReducer