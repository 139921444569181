import axios from 'axios'
import { API_URL } from '../../config/App'

export const actionTypesx = {
    CHANGEX: 'STORE_SUMMARY_FETCHED'
}

export const getStore = (values) => dispatch => {
    //console.log (localStorage.getItem('store_id'));
    return axios.post(`${API_URL}/store/count/${localStorage.getItem('store_id')}`)
            .then(resp => {
                //console.log (resp);
                dispatch([
                    { type: actionTypesx.CHANGEX, payload: resp }
                ])
            })
            .catch(e => {
                dispatch(console.log (e))
                //e.response.data.errors.forEach(
                  //  error => toastr.error('Erro', error))
            })
}
