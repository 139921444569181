import { combineReducers } from 'redux'
import loadingReducer from './loading.reducer'
import notifyReducer from './notify.reducer'
import alertReducer from './alert.reducer'
import paymentReducer from './payment.reducer'
import paymentReducerPS from './payment.reducerps'
import loginReducer from './login.reducer'
import storeReducer from './store.reducer'

const rootReducer = combineReducers({
    loadingReducer,
    notifyReducer,
    alertReducer,
    paymentReducer,
    paymentReducerPS,
    loginReducer,
    storeReducer    
})

export default rootReducer;