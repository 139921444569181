import IO from 'socket.io-client';

export const appSocket = null;

//export const appSocket = IO("https://mybrainchat.herokuapp.com/", {
//  secure: true
//});

export const BASE_URL = 'https://www.mybrain.com.br/'
export const API_URL = 'https://www.mybrain.com.br/api'
export const rootUrl = 'https://www.mybrain.com.br/api'
export const MP_PUBLIC_KEY = localStorage.getItem('token');
//export const PS_PUBLIC_KEY_OFICIAL = '053AE8DDDC704E6FAB2C1A874AEBD593'
//export const PS_PUBLIC_KEY = 'E882B2F7A5384444B0197A7BDA2FF4DE'
export const PS_PUBLIC_KEY_OFICIAL = 'f5f880dd-cb32-4f4f-a41a-ff65ef89a487ea5fe78942db9b019f1638915075829acc71-d473-4b1e-9ca6-cabf0c1abdae'
export const PS_PUBLIC_KEY = 'f5f880dd-cb32-4f4f-a41a-ff65ef89a487ea5fe78942db9b019f1638915075829acc71-d473-4b1e-9ca6-cabf0c1abdae'