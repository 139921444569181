import { actionTypesx } from '../actions/login.action'

const initialState = {
    id: JSON.parse(localStorage.getItem('user_id')), 
    name: '',
    guid: '', 
    email: '',
    cpf: '', 
    status:0
}

const loginReducer = (state = initialState, { type, payload }) => {

    switch (type) {

    case actionTypesx.CHANGEX:
        console.log(payload.data);
        localStorage.setItem('user_id', JSON.stringify(payload.data.id))
        localStorage.setItem('guid', JSON.stringify(payload.data.guid))
        return { ...state, ...payload.data }

    default:
        return state
    }
}

export default loginReducer