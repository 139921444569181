import axios from 'axios'
import { BASE_URL } from '../../config/App'

export const actionTypesx = {
    CHANGEX: 'LOGIN_SUMMARY_FETCHED'
}

export const getLogin234 = (payload) => ({
    type: actionTypesx.CHANGEX,
    payload
})

export const getLogin = (login) => dispatch => {
    return axios.post(`${BASE_URL}/login`,{ login })
            .then(resp => {
                dispatch([
                    { type: actionTypesx.CHANGEX, payload: resp }
                ])
            })
            .catch(e => {
                console.log (e)
                //e.response.data.errors.forEach(
                  //  error => toastr.error('Erro', error))
            })
}
