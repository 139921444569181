import { appSocket } from '../../config/App'
import { Http } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'
import axios from 'axios'
import swal from 'sweetalert'

const store = localStorage.getItem('store_id');
const tel = localStorage.getItem('tel');
const code = localStorage.getItem('code');
const guide = localStorage.getItem('guid');

export const actionTypes = {
    CHANGE: 'PAY_CHANGE',
    SUCCESS: 'PAY_SUCCESS',
    ERROR: 'PAY_ERROR'
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const alertError = (value) => dispatch => {
    dispatch(changeLoading({ open: false }))
    dispatch(changeNotify({
        open: true,
        msg: Object.values(value)[0],
        class: 'error'
    }))
    dispatch(error(value))
}

export const payCard = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando pagamento...'
    }))
    return Http.post('/pay/card', data)
        .then(res => {
            dispatch(changeLoading({open: false}))
            dispatch(success(res.data.id))

            var reference = data['product_id'];
            var status = res.data.payment.status_detail;
            enviarnotificacao(data,status);

            dispatch(change({ pay_type: 'fimcard' }))
            //novopedido(data.user_id,`Novo Pedido - ${data.cel} - ${res.data.payment.description} - ${res.data.payment.payer.identification.number} - ${res.data.payment.payer.email}`, res.data.payment.transaction_amount,reference);

            swal("Pagamento efetuado com sucesso!", {
                icon: "success",
                timer: 2000
                });
    
        })
        .catch(erro => {
            if(erro.response) {
                console.log (erro)
                if(erro.response.data.msg) {
                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}

export const payPec = (data) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Processando pagamento...'
    }))
    return Http.post('/pay/pec', data)
        .then(res => {
            dispatch(changeLoading({open: false}))
            dispatch(success(res.data.id))

            //console.log(res.data);

            var reference = data['product_id'];
            var status = res.data.payment.status_detail;
            enviarnotificacao(data,status);

            dispatch(change({ pay_type: 'fim', urlpec : res.data.payment.transaction_details.external_resource_url }))
            //novopedido(data.user_id,`Novo Pedido - ${data.cel} - ${res.data.payment.description} - ${res.data.payment.payer.identification.number} - ${res.data.payment.payer.email}`, res.data.payment.transaction_amount,reference);

            swal("Boleto gerado com sucesso!", {
                icon: "success",
                timer: 2000
                });

        })
        .catch(erro => {
            if(erro.response) {
                if(erro.response.data.msg) {
                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}


export const payPix = (data) => dispatch => {
    console.log(data);
    dispatch(changeLoading({
        open: true,
        msg: 'Processando pagamento...'
    }))
    return Http.post('/pay/pix', data)
        .then(res => {
            dispatch(changeLoading({open: false}))
            dispatch(success(res.data.id))

            //console.log (res.data);

            var reference = data['product_id'];
            var status = res.data.payment.status_detail;
            enviarnotificacao(data,status);

            dispatch(change({ pay_type: 'fimpix', codpix : res.data.payment.point_of_interaction.transaction_data.qr_code, urlpix: res.data.payment.point_of_interaction.transaction_data.qr_code_base64 }))
            //novopedido(data.user_id,`Novo Pedido - ${data.cel} - ${res.data.payment.description} - ${res.data.payment.payer.identification.number} - ${res.data.payment.payer.email}`, res.data.payment.transaction_amount,reference);

            swal("Código gerado com sucesso!", {
                icon: "success",
                timer: 2000
                });

        })
        .catch(erro => {
            if(erro.response) {
                if(erro.response.data.msg) {
                 
                    if (erro.response.data.msg === "The customer can't be equal to the collector.") {
                        erro.response.data.msg = "O Comprador não pode ser o vendedor! Altere o e-mail e/ou cpf."
                    }

                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}


export const checarPagamento = (data) => dispatch => {
    console.log (data);
    return Http.post('api/pay/check', data)
        .then(res => {
            //dispatch(changeLoading({open: false}))
            //dispatch(success(res.data.id))

            //console.log (res.data);

        })
        .catch(erro => {
            if(erro.response) {
                if(erro.response.data.msg) {
                    dispatch(changeNotify({
                        open: true,
                        msg: erro.response.data.msg,
                        class: 'error'
                    }))
                }
                if(erro.response.data.error) {
                    dispatch(error(erro.response.data.error))
                }
            }
            dispatch(changeLoading({ open: false }))
        })
}

function enviarnotificacao(data, status) {

    switch(status) {
        case 'accredited':
            status = 'Pago'
          break;
        case 'pending_waiting_transfer':
            status = 'Aguardando Pagamento'
          break;
        case 'pending_waiting_payment':
            status = 'Aguardando Pagamento'
          break;
        default:                   
      }

    if (!data.first_name) {
        data.first_name = data.cardholderName;
        data.last_name = '';
    }

    var message = 'Você recebeu uma notificação de pedido. Cliente: ' + data.first_name + ' ' + data.last_name + ' Valor: ' + data.amount + ' reais. Whatsapp: ' + data.cel + ' Situação do Pedido: ' +  status; 
    enviarmsg(tel, code, message);

}



function enviarmsg(phone, code, msg) {

    axios.post('https://www.mybrain.com.br/api/sendmessagenew', {
        phone,
        msg,
        port: 8001
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

}
 
function novopedido(user_id,name,value,reference) {

    var d = new Date();
    var month = d.getMonth() +1;
    var year = d.getFullYear();

    axios ({
        method: 'POST',
        url: 'https://comendus.com.br/api/billings',
        data: {'user_id': user_id, 'store_id': store, reference: reference, 'guid' : guide, 'automatic': 1, 'name' : name, 'month' : month, 'year': year, 'credits': [{'name' : 'Pedido', 'type' : 1, 'value' : value}], 'debts': [{'name' : 'Taxa', 'type' : 2, 'value' : 0.25, 'status' : 'A PAGAR'}] },
        dataType: 'json',
        contentType:'application/json',
    }).then(function (response) {

        //console.log (response.data);

        appSocket.emit("enviar mensagem", {
            origem: "Jefferson",
            msg: 'Pedido realizado ' + store,
            reference: reference,
            store: store
          });

    }).catch(function (error) {

        console.log('Error: ', error);
    });

}