import React, { Suspense, lazy } from 'react'
import { CircularProgress } from '@material-ui/core'

const Routes = (props) => {

const selecionafp = () => {
    var Payment = null;
    if (props.store_payment === 'MP') {
        Payment = lazy(() => import('../view/payment'))
    } 
    else if (props.store_payment === 'PM') {
        Payment = lazy(() => import('../view/payment/indexpagarme'))
    }
    else {
        Payment = lazy(() => import('../view/payment/indexpagseguro'))
    }
    return <Payment/>
}

return (
        <Suspense fallback={<div className="d-flex justify-content-center align-items-center min-vh-100"> <CircularProgress/> </div>}>        
        {selecionafp()}
        </Suspense>
    )

}

export default Routes