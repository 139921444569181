import { actionTypes } from '../actions/payment.actionps'

const initialState = {
    pay_type: null,
    pay_value: 0,
    success: null,
    session: null,
    error: {}
}

const paymentReducerPS = (state = initialState, { type, payload }) => {
    switch (type) {

    case actionTypes.CHANGE:
        //console.log (payload)
        return { ...state, ...payload }

    case actionTypes.SUCCESS:
        return { 
            ...state,
            success: payload
        }

    case actionTypes.SESSION:
        console.log (payload)
        return { 
            ...state,
            session: payload
        }
    

    case actionTypes.ERROR:
        return { 
            ...state, 
            error: payload 
        }

    default:
        return state
    }
}

export default paymentReducerPS