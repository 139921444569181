import React from 'react';
import { Provider } from 'react-redux'
import { Auth0Provider } from "@auth0/auth0-react"
import { store } from './store/store'

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Routes from './routes'
import { Loading, Notify, Alert } from './view/components'
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/global.css'

const theme = createTheme({
    palette: {
        primary: {
            main: '#007bff'
        },
    },
    props: {
        MuiTextField: {
            variant: 'outlined',
            fullWidth: true
        },
        MuiSelect: {
            variant: 'outlined',
            fullWidth: true
        }
    }
});

const App = () => {

    const [ store_id, setStore_Id ] = React.useState(0)
    const [ store_payment, setStore_Payment ] = React.useState(0)

    let data = [];

    React.useEffect(() => {

        getData();

    }, [data])

    function getData() {

        let url = process.env.PUBLIC_URL + "json/default.json";
        fetch(url)
            .then((response) => response.json()
                .then((data) => {
                    if(response.ok) {
                        setStore_Id(data.store_id);
                        setStore_Payment(data.store_payment);
                        localStorage.setItem('store_id', data.store_id)
                        localStorage.setItem('store_payment', data.store_payment)
                        localStorage.setItem('logo', data.logo)
                        localStorage.setItem('guid', data.guid)
                        localStorage.setItem('token', data.token)
                        localStorage.setItem('tel', data.tel)
                        localStorage.setItem('code', data.code)
                    }
                })
            ).catch(
            error => console.log(error)
            );
    }
    
return (
    <Auth0Provider
    domain="dev-ms11wfbx.us.auth0.com"
    clientId="NWYbhL49bDsStEkycxta5HW6c1KSe6SI"
    redirectUri={window.location.origin}
  >
        <Provider store={store}>
        <ThemeProvider theme={theme}>
            <Alert />
            <Notify />
            <Loading />
            <Routes store={store_id} store_payment={store_payment} />
        </ThemeProvider>
    </Provider>
    </Auth0Provider>
    )    
}

export default App;